<template>
  <div>
    <section class="faq-contact">
      <b-row class="text-center">
        <b-col sm="12">
          <b-card
            no-body
          >
            <h2 class="text-primary mt-3">
              Рекомендуйте архив МойЕГРН и зарабатывайте <feather-icon
                icon="WindIcon"
                color="red"
              />
            </h2>
            <b-card-text class="mb-3">
              Получайте <feather-icon
                icon="WindIcon"
                color="red"
              /> за взаимодействие Ваших рефералов с архивом МойЕГРН
            </b-card-text>

            <h2>Сколько и за какие действия рефералов Вы получите <feather-icon
              icon="WindIcon"
              color="red"
            /></h2>
            <b-card-text class="mb-3">
              5 <feather-icon
                icon="WindIcon"
                color="red"
              /> за загруженый и принятый файл
              <br>
              25 <feather-icon
                icon="WindIcon"
                color="red"
              /> за каждую продажу файла
              <br>
            </b-card-text>

            <h2>Ваша реферальная ссылка:</h2>
            <b-card-text class="mb-3">
              <a :href="reflink">{{ reflink }}</a>
              <br>
            </b-card-text>
          </b-card>
        </b-col>
        <b-col sm="12">

          <b-card
            no-body
          >
            <h3 class="m-2">История начислений</h3>
            <div class="m-2">
              <b-row>
                <b-col
                  cols="12"
                  md="6"
                  class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                >
                  <label>Операций<br> на странице</label>
                  <v-select
                    v-model="perPage"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="perPageOptions"
                    :clearable="false"
                    class="per-page-selector d-inline-block ml-50 mr-1"
                  />
                </b-col>

                <!-- Search -->
                <b-col
                  cols="12"
                  md="6"
                >
                  <div class="d-flex align-items-center justify-content-end">
                    <b-form-input
                      v-model="searchQuery"
                      class="d-inline-block mr-1"
                      :placeholder="t('Search') + '...'"
                    />
                    <v-select
                      v-model="statusFilter"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="typeOptions"
                      item-text="text"
                      item-value="value"
                      class="points-filter-select"
                      placeholder="Выберите тип"
                    >
                      <template #selected-option="{ label }">
                        <span class="text-truncate overflow-hidden">
                          {{ t(label) }}
                        </span>
                      </template>
                    </v-select>
                  </div>
                </b-col>
              </b-row>

            </div>

            <b-table
              ref="refExcerptListTable"
              :items="fetchPointHistory"
              responsive
              :fields="tableColumns"
              primary-key="id"
              :sort-by.sync="sortBy"
              show-empty
              empty-text="У Вас ещё не было операций с баллами"
              :sort-desc.sync="isSortDirDesc"
              class="position-relative"
            >

              <template #head(excerptStatus)>
                <feather-icon
                  icon="TrendingUpIcon"
                  class="mx-auto"
                />
              </template>
              <template #head(client)>
                {{ t('Сlient') }}
              </template>
              <template #head(total)>
                {{ t('Total') }}
              </template>

              <!-- Column: Id -->
              <template #cell(id)="data">
                #{{ data.value }}
              </template>

              <!-- Column: Excerpt Status -->
              <template #cell(status)="data">
                <b-avatar
                  :id="`excerpt-row-${data.item.id}`"
                  size="32"
                  :variant="`light-${resolveExcerptStatusVariantAndIcon(data.item.status).variant}`"
                >
                  <feather-icon
                    :icon="resolveExcerptStatusVariantAndIcon(data.item.status).icon"
                  />
                </b-avatar>
                <b-tooltip
                  :target="`excerpt-row-${data.item.id}`"
                  placement="top"
                >
                  <p class="mb-0">
                    {{ t(data.item.status) }}
                  </p>
                </b-tooltip>
              </template>

              <!-- Column: Client -->
              <template #cell(client)="data">
                <b-media vertical-align="center">
                  <template #aside>
                    <b-avatar
                      size="32"
                      :src="data.item.avatar"
                      :text="avatarText(data.item.client.name)"
                      :variant="`light-${resolveClientAvatarVariant(data.item.excerptStatus)}`"
                    />
                  </template>
                  <span class="font-weight-bold d-block text-nowrap">
                    {{ data.item.client.name }}
                  </span>
                  <small class="text-muted">{{ data.item.client.companyEmail }}</small>
                </b-media>
              </template>

              <!-- Column: Issued Date -->
              <template #cell(dateCreate)="data">
                <span class="text-nowrap">
                  {{ new Date(data.value).toLocaleDateString() + " " + new Date(data.value).toLocaleTimeString() }}
                </span>
              </template>

              <!-- Column: Actions -->
              <template #cell(sum)="data">
                <div :class="'text-nowrap text-'+(data.value > 0 ? 'success' : 'danger')">
                  <b>
                    {{ data.value }}
                    <feather-icon
                      icon="WindIcon"
                      color="red"
                    />
                  </b>
                </div>
              </template>

              <template #cell(newBalance)="data">
                <div class="text-nowrap">
                  <b>
                    {{ data.value }}
                    <feather-icon
                      icon="WindIcon"
                      color="red"
                    />
                  </b>
                </div>
              </template>

              <template #cell(operation)="data">
                {{ GetOparationTitle(data.item) }}
              </template>
            </b-table>

            <div class="mx-2 mb-2">
              <b-row>

                <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-start"
                >
                  <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
                </b-col>
                <!-- Pagination -->
                <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-end"
                >

                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalExcerpts"
                    :per-page="perPage"
                    first-number
                    last-number
                    class="mb-0 mt-1 mt-sm-0"
                    prev-class="prev-item"
                    next-class="next-item"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>

                </b-col>

              </b-row>
            </div>
          </b-card>
        </b-col>
        <b-col sm="12">

          <b-card
            no-body
          >
            <h3 class="m-2">Ваши рефералы</h3>

            <b-table
              ref="refRefListTable"
              :items="fetchRefList"
              responsive
              :fields="tableColumnsRefList"
              primary-key="id"
              :sort-by.sync="sortByRefList"
              show-empty
              empty-text="У Вас ещё нет рефералов"
              :sort-desc.sync="isSortDirDescRefList"
              class="position-relative"
            >

              <template #head(excerptStatus)>
                <feather-icon
                  icon="TrendingUpIcon"
                  class="mx-auto"
                />
              </template>
              <template #head(client)>
                {{ t('Сlient') }}
              </template>
              <template #head(total)>
                {{ t('Total') }}
              </template>

              <!-- Column: Id -->
              <template #cell(id)="data">
                #{{ data.value }}
              </template>

              <!-- Column: Excerpt Status -->
              <template #cell(status)="data">
                <b-avatar
                  :id="`excerpt-row-${data.item.id}`"
                  size="32"
                  :variant="`light-${resolveExcerptStatusVariantAndIcon(data.item.status).variant}`"
                >
                  <feather-icon
                    :icon="resolveExcerptStatusVariantAndIcon(data.item.status).icon"
                  />
                </b-avatar>
                <b-tooltip
                  :target="`excerpt-row-${data.item.id}`"
                  placement="top"
                >
                  <p class="mb-0">
                    {{ t(data.item.status) }}
                  </p>
                </b-tooltip>
              </template>

              <!-- Column: Client -->
              <template #cell(client)="data">
                <b-media vertical-align="center">
                  <template #aside>
                    <b-avatar
                      size="32"
                      :src="data.item.avatar"
                      :text="avatarText(data.item.client.name)"
                      :variant="`light-${resolveClientAvatarVariant(data.item.excerptStatus)}`"
                    />
                  </template>
                  <span class="font-weight-bold d-block text-nowrap">
                    {{ data.item.client.name }}
                  </span>
                  <small class="text-muted">{{ data.item.client.companyEmail }}</small>
                </b-media>
              </template>

              <!-- Column: Issued Date -->
              <template #cell(dateCreate)="data">
                <span class="text-nowrap">
                  {{ new Date(data.value).toLocaleDateString() + " " + new Date(data.value).toLocaleTimeString() }}
                </span>
              </template>

              <!-- Column: Actions -->
              <template #cell(sum)="data">
                <div :class="'text-nowrap text-'+(data.value > 0 ? 'success' : 'danger')">
                  <b>
                    {{ data.value ? data.value : 0 }}
                    <feather-icon
                      icon="WindIcon"
                      color="red"
                    />
                  </b>
                </div>
              </template>

              <template #cell(newBalance)="data">
                <div class="text-nowrap">
                  <b>
                    {{ data.value }}
                    <feather-icon
                      icon="WindIcon"
                      color="red"
                    />
                  </b>
                </div>
              </template>

              <template #cell(operation)="data">
                {{ GetOparationTitle(data.item) }}
              </template>
            </b-table>

            <div class="mx-2 mb-2">
              <b-row>

                <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-start"
                >
                  <span class="text-muted">Showing {{ dataMetaRefList.from }} to {{ dataMetaRefList.to }} of {{ dataMetaRefList.of }} entries</span>
                </b-col>
                <!-- Pagination -->
                <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-end"
                >

                  <b-pagination
                    v-model="currentPageRefList"
                    :total-rows="totalExcerpts"
                    :per-page="perPageRefList"
                    first-number
                    last-number
                    class="mb-0 mt-1 mt-sm-0"
                    prev-class="prev-item"
                    next-class="next-item"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>

                </b-col>

              </b-row>
            </div>
          </b-card>
        </b-col>
        <b-col sm="6">
          <b-card
            class="shadow-none py-1 faq-contact-card"
          >
            <b-avatar
              size="42"
              rounded
              variant="light-primary"
              class="mb-2"
            >
              <feather-icon
                icon="SendIcon"
                size="18"
              />
            </b-avatar>
            <h4><b-link href="https://t.me/myegrnbot" target="_blank">@MyEGRNBot</b-link></h4>
            <span class="text-body">Есть вопросы, можете, адресовать их нашему боту поддержки в Telegram</span>
          </b-card>
        </b-col>
        <b-col sm="6">
          <b-card
            class="shadow-none py-1 faq-contact-card"
          >
            <b-avatar
              size="42"
              rounded
              variant="light-primary"
              class="mb-2"
            >
              <feather-icon
                icon="MailIcon"
                size="18"
              />
            </b-avatar>
            <h4><b-link href="mail:support@myegrn.ru" target="_blank">support@myegrn.ru</b-link></h4>
            <span class="text-body">Нужна помощь или хотите что нибудь нам посоветовать?<br>Наша электронная почта всегда к Вашим услугам</span>
          </b-card>
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import {
  BCard, BCardText, BRow, BCol, BAvatar,
  BFormInput, BTable, BMedia,
  BPagination, BTooltip,
  BLink,
} from 'bootstrap-vue'
import { onUnmounted } from '@vue/composition-api'
import vSelect from 'vue-select'
import store from '@/store'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import usePartners from './usePartners'

import partnersStoreModule from './partnersListStoreModule'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BAvatar,
    BCardText,
    BFormInput,
    BTable,
    BMedia,
    BPagination,
    BTooltip,
    vSelect,
    BLink,
  },
  setup() {
    const idUser = JSON.parse(localStorage.getItem('userData')).id
    const reflink = `https://${window.location.host}/ref=${idUser}`

    const { t } = useI18nUtils()
    const APP_STORE_MODULE_NAME = 'app-partners'
    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, partnersStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })

    const {
      fetchPointHistory,
      tableColumns,
      perPage,
      currentPage,
      totalExcerpts,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refExcerptListTable,
      statusFilter,

      refetchData,

      resolveExcerptStatusVariantAndIcon,
      resolveClientAvatarVariant,

      fetchRefList,
      isSortDirDescRefList,
      tableColumnsRefList,
      sortByRefList,
      refRefListTable,
      dataMetaRefList,
      currentPageRefList,
      perPageRefList,
      perPageOptionsRefList,
      searchQueryRefList,
      statusFilterRefList,
    } = usePartners()

    const typeOptions = [
      { value: 'file-referal-upload', label: 'Загрузка рефералом', desc: 'Загрузка файла вашим рефералом #' },
      { value: 'file-referal-sale', label: 'Продажа рефералом', desc: 'Продажа файла вашим рефералом #' },
    ]
    const GetOparationTitle = item => {
      const option = typeOptions.filter(ii => ii.value === item.operation)[0]
      if (option) {
        return `${option.desc}${item.idObjectOperation}`
      }
      return item.operation
    }

    return {
      typeOptions,
      GetOparationTitle,
      fetchPointHistory,
      tableColumns,
      perPage,
      currentPage,
      totalExcerpts,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refExcerptListTable,
      statusFilter,

      refetchData,

      resolveExcerptStatusVariantAndIcon,
      resolveClientAvatarVariant,

      fetchRefList,
      isSortDirDescRefList,
      tableColumnsRefList,
      sortByRefList,
      refRefListTable,
      dataMetaRefList,
      currentPageRefList,
      perPageRefList,
      perPageOptionsRefList,
      searchQueryRefList,
      statusFilterRefList,

      t,
      reflink,
    }
  },
}
</script>
<style lang="scss">

.points-filter-select {
  min-width: 242px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}

</style>
<style lang="scss">
@import '@core/scss/vue/pages/page-faq.scss';
</style>
